<template>
    <div style="padding: 0 100px;">
        <div class="column page-size" style="padding: 10px;">
            <div class="first-title">个人中心</div>
            <el-divider></el-divider>
            <div class="second-title">
                <el-row :gutter="20">
                    <el-col :span="3">
                        <div class="grid-content bg-purple">基本信息</div>
                    </el-col>
                    <el-col :span="6">
                        <el-button v-if="!isEditing" class="grid-content bg-purple" @click="editInfo">编辑</el-button>
                        <el-button v-else class="grid-content bg-purple" @click="saveInfo">保存</el-button>
                    </el-col>
                </el-row>
            </div>
            <div class="content">
                <el-row :gutter="20">
                    <el-col :span="2">
                        <div class="grid-content bg-purple">姓名</div>
                    </el-col>
                    <el-col :span="6">
                        <div v-if="!isEditing" class="grid-content bg-purple">{{ name }}</div>
                        <el-input v-else class="grid-content bg-purple" :placeholder="name" v-model="name"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <div class="grid-content bg-purple">昵称</div>
                    </el-col>
                    <el-col :span="6">
                        <div v-if="!isEditing" class="grid-content bg-purple">{{ maskName }}</div>
                        <el-input v-else class="grid-content bg-purple" :placeholder="maskName"
                                  v-model="maskName"></el-input>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="2">
                        <div class="grid-content bg-purple">性别</div>
                    </el-col>
                    <el-col :span="6">
                        <div v-if="!isEditing" class="grid-content bg-purple">{{ sex }}</div>
                        <el-input v-else class="grid-content bg-purple" :placeholder="sex" v-model="sex"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <div class="grid-content bg-purple">出生年月</div>
                    </el-col>
                    <el-col :span="6">
                        <div v-if="!isEditing" class="grid-content bg-purple">{{ birthday }}</div>
                        <el-date-picker v-else class="grid-content bg-purple" v-model="birthday" type="month"
                                        format="yyyy-MM"/>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="2">
                        <div class="grid-content bg-purple">联系方式</div>
                    </el-col>
                    <el-col :span="6">
                        <div v-if="!isEditing" class="grid-content bg-purple">{{ phone }}</div>
                        <el-input v-else class="grid-content bg-purple" :placeholder="phone" v-model="phone"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <div class="grid-content bg-purple">居住省市</div>
                    </el-col>
                    <el-col :span="6">
                        <div v-if="!isEditing" class="grid-content bg-purple">{{ city }}</div>
                        <el-input v-else class="grid-content bg-purple" :placeholder="city" v-model="city"></el-input>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="2">
                        <div class="grid-content bg-purple">微信账号</div>
                    </el-col>
                    <el-col :span="6">
                        <div v-if="!isEditing" class="grid-content bg-purple">{{ weChat }}</div>
                        <el-input v-else class="grid-content bg-purple" :placeholder="weChat"
                                  v-model="weChat"></el-input>
                    </el-col>
                </el-row>
            </div>
            <el-divider></el-divider>
            <div v-if="userType==UserType.owner">
                <div class="second-title">
                    <el-row :gutter="20" class="second-title">
                        <el-col :span="3">
                            <div class="grid-content bg-purple">使用者信息</div>
                        </el-col>
                        <el-col :span="6">
                            <el-button v-if="!isEditing2" class="grid-content bg-purple" @click="editInfo2">编辑
                            </el-button>
                            <el-button v-else class="grid-content bg-purple" @click="saveInfo2">保存</el-button>
                        </el-col>
                    </el-row>
                </div>
                <div class="content">
                    <el-row :gutter="20">
                        <el-col :span="2">
                            <div class="grid-content bg-purple">姓名</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ userName }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="userName"
                                      v-model="userName"></el-input>
                        </el-col>
                        <el-col :span="2">
                            <div class="grid-content bg-purple">出生年月</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ userBirthday }}</div>
                            <el-date-picker v-else class="grid-content bg-purple" v-model="userBirthday" type="month"
                                            format="yyyy-MM"/>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="2">
                            <div class="grid-content bg-purple">性别</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ userSex }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="userSex"
                                      v-model="userSex"></el-input>
                        </el-col>
                        <el-col :span="2">
                            <div class="grid-content bg-purple">联系方式</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ userPhone }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="userPhone"
                                      v-model="userPhone"></el-input>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="2">
                            <div class="grid-content bg-purple">居住省市</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ userProvince }}</div>
                            <el-select class="grid-content bg-purple" v-else v-model="userProvince" filterable
                                       placeholder="请选择">
                                <el-option
                                        v-for="item in AllProvinceDate"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="2">
                            <div class="grid-content bg-purple">详细地址</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ userAddress }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="userAddress"
                                      v-model="userAddress"></el-input>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="2">
                            <div class="grid-content bg-purple">与本人关系</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ relationship }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="relationship"
                                      v-model="relationship"></el-input>
                        </el-col>
                        <el-col :span="2">
                            <div class="grid-content bg-purple">绑定设备编号</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ macNo }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="macNo"
                                      v-model="macNo"></el-input>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="2">
                            <div class="grid-content bg-purple">签约医生联系电话</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ doctorPhone }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="doctorPhone"
                                      v-model="doctorPhone"></el-input>
                        </el-col>
                        <el-col :span="2">
                            <div class="grid-content bg-purple">所在居委会联系电话</div>
                        </el-col>
                        <el-col :span="6">
                            <div v-if="!isEditing2" class="grid-content bg-purple">{{ committeePhone }}</div>
                            <el-input v-else class="grid-content bg-purple" :placeholder="committeePhone"
                                      v-model="committeePhone"></el-input>
                        </el-col>
                    </el-row>
                </div>

                <el-divider></el-divider>
                <div class="second-title">
                    <el-row :gutter="20">
                        <el-col :span="4">
                            <div class="grid-content bg-purple">使用者健康情况</div>
                        </el-col>
                        <el-col :span="6">
                            <el-button class="grid-content bg-purple">编辑</el-button>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ca from "element-ui/src/locale/lang/ca";
import Da from "element-ui/src/locale/lang/da";
import VueMixin from "@/utils/VueMixin";
import {UserType} from "@/utils/common";

export default {
    name: "UserCenter",
    computed: {
        UserType() {
            return UserType
        }
    },
    mixins: [VueMixin],
    data() {
        return {
            name: "未设置",
            maskName: "无",
            sex: "",
            birthday: "1970/1",
            phone: "",
            city: "",
            weChat: "",
            userName: "",
            userBirthday: "1970/1",
            userSex: "",
            userPhone: "",
            userProvince: "",
            userAddress: "",
            relationship: "",
            macNo: "",
            doctorPhone: "",
            committeePhone: "",
            isEditing: false,
            isEditing2: false,
            dtoSex: "MAN",
            dtoUserSex: "MAN",
            AllProvinceDate: [],
            userType: undefined,
        }
    },
    mounted() {
        //初始化用户信息
        this.initData();
    },
    methods: {
        dataDTO() {
            if (this.sex.includes("男")) {
                this.dtoSex = "MAN"
            } else {
                this.dtoSex = "WOMAN"
            }

            if (this.userSex.includes("男")) {
                this.dtoUserSex = "MAN"
            } else {
                this.dtoUserSex = "WOMAN"
            }
        },
        async initData() {
            this.userType = localStorage.getItem("type");
            let url = `${this.$api.getProvinces}`;
            let res = await this.$http.get(url);
            if (res.data.code === 0) {
                console.log(res);
                let temp = res.data.data;
                for (let province of temp) {
                    let obj = {};
                    obj["value"] = province;
                    obj["label"] = province;
                    this.AllProvinceDate.push(obj);
                }
            } else {
                this.popWindow(res.data.message, "失败");
            }
            url = `${this.$api.getOwnerUserInfo}`;
            this.$http.get(url).then(res => {
                console.log(res);
                if (res.data.code === 0) {
                    let owner_info_response = res.data.data.owner_info_response;
                    let user_info_response = res.data.data.user_info_response;
                    this.initOwnerInfo(owner_info_response);
                    this.initUserInfo(user_info_response);
                    this.$forceUpdate()
                } else {
                    this.popWindow(res.data.message, "失败");
                }
            });


        },
        editInfo() {
            this.isEditing = true;
        },
        editInfo2() {
            this.isEditing2 = true;
        },
        onInput() {
            this.$forceUpdate();
        },
        saveInfo() {
            this.dataDTO();
            let url = undefined;
            url = `${this.$api.updateOwnerInfo}?name=${this.name}&nickname=${this.maskName}&gender=${this.dtoSex}&birth=${this.convertDateString(this.birthday)}&phone=${this.phone}&address=${this.city}&wechatAccount=${this.weChat}`
            let temp = decodeURIComponent(this.convertDateString(this.birthday))
            this.birthday = temp.substring(0, temp.lastIndexOf("/"));
            this.$http.post(url).then(res => {
                console.log(res)
                if (res.data.code === 0) {
                    this.$alert(res.data.message, '成功', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'info',
                                message: `action: ${action}`
                            });
                        }
                    })
                } else {
                    this.$alert(res.data.message, '失败', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'info',
                                message: `action: ${action}`
                            });
                        }
                    })
                }
            })
            this.isEditing = false;
        },
        saveInfo2() {
            this.dataDTO();
            let url = `${this.$api.updateUserInfo}?name=${this.userName}&birth=${this.convertDateString(this.userBirthday)}&gender=${this.dtoUserSex}&detailAddress=${this.userAddress}&province=${this.userProvince}&phone=${this.userPhone}&relationship=${this.relationship}&deviceCode=${this.macNo}&doctorPhone=${this.doctorPhone}&hoodPhone=${this.committeePhone}`
            let temp = decodeURIComponent(this.convertDateString(this.userBirthday))
            this.userBirthday = temp.substring(0, temp.lastIndexOf("/"));
            console.log(url)
            this.$http.post(url).then(res => {
                console.log(res)
                if (res.data.code === 0) {
                    this.$alert(res.data.message, '成功', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'info',
                                message: `action: ${action}`
                            });
                        }
                    })
                } else {
                    this.$alert(res.data.message, '失败', {
                        confirmButtonText: '确定',
                        callback: action => {
                            this.$message({
                                type: 'info',
                                message: `action: ${action}`
                            });
                        }
                    })
                }
            })
            this.isEditing2 = false;
        },
        convertDateString(inputString) {
            if (inputString instanceof String) {
                let dateString = inputString.replace(/\./g, "%2F") + "%2F01";
                dateString = dateString.replace(/-/g, "%2F");
                return dateString;
            } else {
                let date = new Date(inputString)
                let temp = date.getMonth() + 1
                return `${date.getFullYear()}%2F${temp}%2F01`
            }
        },
        initOwnerInfo(owner_info_response) {
            try {
                this.city = owner_info_response["address"]
                this.birthday = this.getDateFromNumber(owner_info_response["birth"])
                if (this.birthday === 'NaN/NaN') {
                    this.birthday = ''
                }
                this.name = owner_info_response["name"]
                this.maskName = owner_info_response["nickname"]
                this.phone = owner_info_response["phone"]
                this.weChat = owner_info_response["wechat_account"]
                this.dtoSex = owner_info_response["gender"]
                if (this.dtoSex === "WOMAN") {
                    this.sex = "女"
                } else {
                    this.sex = "男"
                }
            } catch (e) {
                console.log(e)
            }
        },
        initUserInfo(user_info_response) {
            try {
                user_info_response = user_info_response[0]
                this.userAddress = user_info_response["detail_address"] === undefined ? "未知" : user_info_response["detail_address"]
                this.userProvince = user_info_response["province"] === undefined ? "未知" : user_info_response["province"]
                this.userBirthday = user_info_response["birth"] === undefined ? "未知" : this.getDateFromNumber(user_info_response["birth"])
                this.userName = user_info_response["name"] === undefined ? "未知" : user_info_response["name"]
                this.userPhone = user_info_response["phone"] === undefined ? "未知" : user_info_response["phone"]
                this.relationship = user_info_response["relationship"] === undefined ? "未知" : user_info_response["relationship"]
                this.committeePhone = user_info_response["hood_phone"] === undefined ? "未知" : user_info_response["hood_phone"]
                this.dtoUserSex = user_info_response["gender"] === undefined ? "未知" : user_info_response["gender"]
                this.macNo = user_info_response["device_code"] === undefined ? "未知" : user_info_response["device_code"]
                this.doctorPhone = user_info_response["doctor_phone"] === undefined ? "未知" : user_info_response["doctor_phone"]
                if (this.dtoUserSex === "WOMAN") {
                    this.userSex = "女"
                } else {
                    this.userSex = "男"
                }
            } catch (e) {
                console.log(e)
            }
        },
        getDateFromNumber(milliseconds) {
            const date = new Date(milliseconds);
            const year = date.getFullYear();
            const month = date.getMonth() + 1; // 月份从0开始，所以要加1
            return `${year}/${month}`;
        }
    }
}
</script>

<style scoped>
.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.content {
    font-size: 20px;
    margin-left: 100px;
}


.bg-purple-dark {
    background: #99a9bf;
}

.bg-purple {
    /*background: #d3dce6;*/
}

.bg-purple-light {
    background: #e5e9f2;
}

.grid-content {
    border-radius: 4px;
    min-height: 36px;
}

.row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
}

.first-title {
    font-size: 30px;
    font-weight: bold;
}

.second-title {
    font-size: 25px;
    margin-left: 50px;
    margin-bottom: 20px;
}
</style>